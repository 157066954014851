
import "bootswatch/dist/journal/bootstrap.min.css";
import Home from "./components/Home";
import { NavBar } from './components'




function App() {
  return (
      <div>
        <NavBar />
       <Home /> 
       
      </div>
     
      
   
  );
}

export default App;
